body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.song-card {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.2s;
  color: white;
  user-select: none;
}

.song-card:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(0deg, black 0%, transparent 40%);
  z-index: 2;
}

.song-card:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.35);
  cursor: pointer;
}
/* New Content */
.song-card:hover .song-card_play {
  transform: translateX(0);
  opacity: 1;
}
.song-card:hover .song-card_info {
  transform: translateX(100%);
  opacity: 0;
}

.song-card_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.song-card_info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 10px;
  text-align: center;
  transform: translateX(0);
  opacity: 1;
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: ease-in;
}
.song-card_info_artist {
  font-size: 1em;
  font-weight: bold;
  line-height: 1.2em;
}
.song-card_info_album {
  font-size: 0.7em;
  line-height: 1em;
}
.song-card_info_title {
  margin-top: 8px;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: bold;
}
.song-card_info_artist,
.song-card_info_album,
.song-card_info_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.song-card_play {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 3;
  cursor: pointer;
  opacity: 0;
  transform: translateX(-60px);
  transition: opacity 0.2s, transform 0.2s;
  transition-timing-function: ease-in;
}
.song-card_play:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 0;
  height: 0;
  margin: auto;
}

.become-artist {
  border: none;
  color: #fff;
  background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
  border-radius: 20px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 0.6em 1.5em;
}

.become-artist:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: arishuy 1.5s infinite;
}

@keyframes arishuy {
  0% {
    box-shadow: 0 0 0 0 #05bada66;
  }

  70% {
    box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
}
